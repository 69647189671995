




















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { inject } from 'inversify-props'

@Component({ components: {} })
export default class extends Vue {
  @Prop({ default: false })
  loading: boolean

  @Prop({ default: false })
  success: boolean

  @Prop({ default: true })
  onboarding: boolean

  @Emit()
  retry() {
    return
  }

  @Emit()
  downloadResult() {
    return
  }

  created() {
    console.log('created')
  }
}
