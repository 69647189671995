























import { Component, Vue } from 'vue-property-decorator'
import { inject } from 'inversify-props'
import { Interview } from 'ritem-common'
import { InterviewsClient } from 'ritem-common'
import InterviewSuccessMessage from '@/components/InterviewSuccessMessage.vue'

@Component({ components: { Interview, InterviewSuccessMessage } })
export default class Onboarding extends Vue {
  @inject()
  interviewsClient: InterviewsClient

  interviewData: string = ''

  completedSurvey = false
  loading = false
  success = false

  lastInterviewResult = ''

  async created() {
    console.log('created onboarding')
    await this.load(this.$route.params.id)
  }

  async load(id: string) {
    const result = await this.interviewsClient.getOnboardingInterviewData(id)
    this.interviewData = JSON.stringify(result)
  }

  applyCssClasses(defaultBootstrapCss: any) {
    defaultBootstrapCss.matrixdynamic.buttonAdd = 'btn btn-secondary'
    defaultBootstrapCss.matrixdynamic.buttonRemove = 'btn btn-primary'
    defaultBootstrapCss.navigation.complete = 'btn sv_complete_btn btn-primary'
    defaultBootstrapCss.navigation.prev = 'btn sv_prev_btn btn-secondary'
    defaultBootstrapCss.navigation.next = 'btn sv_next_btn btn-secondary'
    defaultBootstrapCss.navigation.start = 'btn sv_start_btn btn-secondary'
    defaultBootstrapCss.navigation.preview = 'btn sv_preview_btn btn-secondary'
    defaultBootstrapCss.navigation.edit = 'btn sv_edit_btn btn-secondary'
    defaultBootstrapCss.rating.selected = 'active ratingactive'
    defaultBootstrapCss.signaturepad.clearButton =
      'sjs_sp_clear btn btn-secondary'
    defaultBootstrapCss.file.removeButton =
      'sv_q_file_remove_button btn btn-primary'
    defaultBootstrapCss.paneldynamic.buttonAdd =
      'sv-paneldynamic__add-btn btn btn-secondary'
    defaultBootstrapCss.paneldynamic.buttonRemove =
      'sv_p_remove_btn btn btn-primary'
  }

  async completed(data: any) {
    this.lastInterviewResult = data
    this.completedSurvey = true
    this.loading = true
    await this.onboard(data)
    this.loading = false
  }

  async retry() {
    this.loading = true
    await this.onboard(this.lastInterviewResult)
    this.loading = false
  }

  downloadResult() {
    const a = document.createElement('a')
    const filename = `interview_result_${Date.now()}.json`
    const dataURI =
      'data:application/json;base64,' +
      btoa(JSON.stringify(this.lastInterviewResult))
    a.href = dataURI
    a.download = filename
    const e = document.createEvent('MouseEvents')

    e.initMouseEvent(
      'click',
      true,
      false,
      document.defaultView,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )
    a.dispatchEvent(e)
    a.remove()
  }

  async onboard(data: any) {
    try {
      await this.interviewsClient.onboard(JSON.stringify(data))
      this.success = true
    } catch (ex) {
      this.success = false
    }
  }
}
